import { MDBBtn, MDBIcon } from "mdb-react-ui-kit";
import React, { useRef, useState } from "react";
import getHostName from "../api/config/config";
import { useSnackbar } from "notistack";

const UploadFile = (props) => {
  const { candidateId, asyncData, setAsyncData, getCvs } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [fileSize, setFileSize] = useState(true);
  const inputRef = useRef(null);

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files && event.target.files[0];

    if (!file) {
      return;
    }

    setFileSize(true);

    const formData = new FormData();

    if (file.size > 50000000) {
      setFileSize(false);
      return;
    }

    formData.append("candidateId", candidateId);
    formData.append("files", file);

    const requestOptions = {
      method: "POST",
      body: formData,
    };

    fetch(getHostName() + "/api/v1/cv/upload", requestOptions)
      .then(async (res) => {
        const isJson = res.headers
          .get("content-type")
          .includes("application/json");
        const data = isJson && (await res.json());

        showAlert(res.status, file.name);

        if (!res.ok) {
          const error = (data && data.message) || res.status;
          return Promise.reject(error);
        }
      })
      .catch((error) => {
        console.error("Error while uploading file!", error);
        showAlert(error.response.status, file.name);
      });

    event.target.value = null;
  };

  function showAlert(res, cvName) {
    if (res === 200) {
      enqueueSnackbar("CV " + cvName + " упішно додано", {
        variant: "success",
      });
    } else if (res === 404) {
      enqueueSnackbar("CV " + cvName + " не знайдено", {
        variant: "error",
      });
    } else {
      enqueueSnackbar(res, {
        variant: "error",
      });
    }
  }

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        onChange={(event) => {
          handleFileChange(event);
          setTimeout(() => {
            getCvs();
            setAsyncData({
              ...asyncData,
              rows: [],
            });
          }, 1000);
        }}
        style={{ display: "none" }}
      />
      <MDBBtn block color="warning" type="button" onClick={handleClick}>
        <MDBIcon fas icon="plus" className="me-2"></MDBIcon>
        Додати CV
      </MDBBtn>
      {!fileSize && (
        <p className="mt-2" style={{ color: "red" }}>
          Файл занадто великий! Максимальний допустимий розмір файлу 50МБ.
        </p>
      )}
    </>
  );
};
export default UploadFile;
