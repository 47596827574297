import React, { useEffect, useState } from "react";
import {
  MDBIcon,
  MDBBtn,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdb-react-ui-kit";
import getHostName from "../api/config/config";
import HandlerCvDelete from "../handlers/HandlerCvDelete";
import UploadFile from "./UploadFile";

export default function CvTable(props) {
  const [loading, setLoading] = useState(false);
  const [cvExists, setCvExists] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [asyncData, setAsyncData] = useState({
    columns: [
      { label: "Назва файлу", field: "name" },
      { label: "Розмір файлу", field: "size" },
      { label: "Дії", field: "actions" },
    ],
    rows: [],
  });
  const { candidateId } = props;
  const urlGetAllCandidateCv = getHostName() + "/api/v1/cv/get/" + candidateId;

  const urlDownloadCv = getHostName() + "/api/v1/cv/download/";

  function getCvs() {
    fetch(urlGetAllCandidateCv)
      .then((response) => response.json())
      .then((data) =>
        setTimeout(() => {
          setAsyncData({
            columns: asyncData.columns,
            rows: data.map((row) => ({
              ...row,
              actions: (
                <>
                  <label htmlFor="downloadFile">
                    <MDBBtn
                      tag="a"
                      href={urlDownloadCv + row.id}
                      color="none"
                      style={{ color: "#00a974" }}
                      className="me-3"
                    >
                      <MDBIcon size="lg" fas icon="cloud-download-alt" />
                    </MDBBtn>
                  </label>
                  <HandlerCvDelete
                    id={row.id}
                    name={row.name}
                    asyncData={asyncData}
                    setAsyncData={setAsyncData}
                  ></HandlerCvDelete>
                </>
              ),
            })),
          });
        }, 1000)
      );
  }

  useEffect(() => {
    if (loading && candidateId != null && candidateId !== "") {
      getCvs();
    }
  }, [loading, asyncData.columns]);

  useEffect(() => {
    if (asyncData.rows.length === 0) {
      setLoadingMessage("Завантаження...");
      setLoading(true);
      setTimeout(() => {
        if (asyncData.rows.length === 0) {
          setLoadingMessage("CV не знайдено");
          setCvExists(false);
        } else {
          setCvExists(true);
          setLoading(false);
        }
      }, 2000);
    } else {
      setCvExists(true);
      setLoading(false);
    }
  }, [asyncData.rows]);

  return (
    <>
      {candidateId != null && candidateId !== "" && (
        <>
          <label className="mt-1">Список завантажених CV</label>
          <div className="row m-2 text-center">
            <MDBTable bordered small className="text-white">
              <MDBTableHead>
                <tr>
                  <th scope="row">Назва файлу</th>
                  <th scope="col">Розмір файлу</th>
                  <th scope="col">Дії</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {loading && !cvExists && (
                  <tr>
                    <td colSpan={3}>{loadingMessage}</td>
                  </tr>
                )}
                {!loading &&
                  asyncData.rows.map((row) => (
                    <tr key={row.id}>
                      <td>{row.name}</td>
                      <td>{row.size / 1000 + " KB"}</td>
                      <td>{row.actions}</td>
                    </tr>
                  ))}
              </MDBTableBody>
            </MDBTable>
            <div className="row mt-2 ms-0 p-0">
              <UploadFile
                candidateId={candidateId}
                asyncData={asyncData}
                setAsyncData={setAsyncData}
                getCvs={getCvs}
              ></UploadFile>
            </div>
          </div>
        </>
      )}
    </>
  );
}
