import React, { useEffect, useState } from "react";
import getHostName from "../api/config/config";
import HandlerCandidateDelete from "../handlers/HandlerCandidateDelete";
import CandidateCard from "../modals/CandidateCard";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ukUA } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import GetCvList from "../api/cv/GetCvList";
import { Button, Checkbox } from "@mui/material";

export default function CandidateTable() {
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [asyncData, setAsyncData] = useState({
    columns: [
      {
        headerName: "ID",
        field: "id",
        width: 50,
        headerAlign: "center",
        align: "center",
        hide: true,
      },
      {
        headerName: "ПІБ",
        field: "name",
        width: 201,
        headerAlign: "center",
        align: "center",
      },
      {
        headerName: "Вакансія",
        field: "vacancy",
        width: 110,
        headerAlign: "center",
        align: "center",
      },
      {
        headerName: "Статус",
        field: "status",
        width: 210,
        headerAlign: "center",
        align: "center",
      },
      {
        headerName: "Рекрутер",
        field: "recruiter",
        width: 158,
        headerAlign: "center",
        align: "center",
        hide: true,
      },
      {
        headerName: "LinkedIn",
        field: "linkedIn",
        width: 80,
        headerAlign: "center",
        align: "center",
        renderCell: (row) => (
          <Button
            href={row.row.linkedIn}
            disabled={checkLinkIsEmpty(row.row.linkedIn)}
            target="none"
            color="inherit"
          >
            Link
          </Button>
        ),
      },
      {
        headerName: "Email",
        field: "email",
        width: 150,
        headerAlign: "center",
        align: "center",
      },
      {
        headerName: "Телефон",
        field: "phoneNumber",
        width: 180,
        headerAlign: "center",
        align: "center",
      },
      {
        headerName: "Коментар",
        field: "comment",
        width: 158,
        headerAlign: "center",
        align: "center",
      },
      {
        headerName: "Очікування по ЗП",
        field: "salaryExpectations",
        width: 140,
        headerAlign: "center",
        align: "center",
      },
      {
        headerName: "CV",
        field: "cv",
        width: 46,
        headerAlign: "center",
        align: "center",
        renderCell: (row) => <CvCheckbox row={row} />,
      },
      {
        headerName: "Стадія вакансії",
        field: "vacancyStage",
        width: 140,
        headerAlign: "center",
        align: "center",
      },
      {
        headerName: "Перший робочий день",
        field: "firstWorkDay",
        width: 150,
        headerAlign: "center",
        align: "center",
        renderCell: (row) => <FieldDate row={row}></FieldDate>,
        hide: true,
      },
    ],
    rows: [],
  });

  const checkLinkIsEmpty = (link) => {
    if (link == null || link === "") {
      return true;
    } else {
      return false;
    }
  };

  const FieldDate = (props) => {
    const { row } = props;
    const date = row.row.firstWorkDay;
    if (date !== undefined && date !== null && date !== "") {
      let newDate = new Date(date);
      return newDate.toLocaleDateString();
    } else {
      return "";
    }
  };

  const CvCheckbox = (props) => {
    const { row } = props;
    const cvExists = row.row.cv > 0;
    return <Checkbox disabled readOnly checked={cvExists} />;
  };

  useEffect(() => {
    if (loading) {
      GetCvList().then((res) => {
        fetch(getHostName() + "/api/v1/candidates/get-all-candidates")
          .then((response) => response.json())
          .then((data) =>
            setTimeout(() => {
              setAsyncData({
                columns: asyncData.columns,
                rows: data.map((row) => ({
                  ...row,
                  cv: res.filter((cv) => {
                    return cv.candidateId === row.id;
                  }).length,
                })),
              });
            }, 1000)
          );
      });
    }
  }, [loading, asyncData.columns]);

  useEffect(() => {
    if (asyncData.rows.length === 0) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [asyncData.rows]);

  const theme = createTheme(
    {
      palette: {
        primary: { main: "#1976d2" },
      },
    },
    ukUA
  );

  function CustomToolbar() {
    return (
      <GridToolbarContainer className="d-flex justify-content-between align-items-center">
        <GridToolbarColumnsButton className="toolbar-button" />
        <GridToolbarFilterButton className="toolbar-button" />
        <GridToolbarDensitySelector className="toolbar-button" />
        <GridToolbarExport className="toolbar-button" />
        <HandlerCandidateDelete id={selectionModel[0]} />
        <CandidateCard
          className="toolbar-button"
          candidateId={selectionModel[0]}
          row={
            asyncData.rows.filter((element) => {
              return element.id === selectionModel[0];
            })[0]
          }
        />
        <GridToolbarQuickFilter className="toolbar-search me-3" />
      </GridToolbarContainer>
    );
  }

  return (
    <div
      style={{
        height: "91vh",
        width: "100%",
      }}
    >
      <ThemeProvider theme={theme}>
        <DataGrid
          density="compact"
          showCellRightBorder
          className="text-center"
          rows={asyncData.rows}
          columns={asyncData.columns}
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
          }}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          loading={loading}
        />
      </ThemeProvider>
    </div>
  );
}
