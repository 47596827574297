import axios from "axios";
import getHostName from "../config/config";

export default async function SaveStatusService(status) {
  try {
    const response = await axios.post(
      getHostName() + "/api/v1/status/save/",
      status
    );
    return response.status;
  } catch (error) {
    if (error.response) {
      return error.response.status;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
}
